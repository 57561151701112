/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'message': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="#154f4a" fill-rule="nonzero"><path pid="0" d="M2 3a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1H2zm0-1h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V4a2 2 0 012-2z"/><path pid="1" d="M.695 3.396l.61-.792L8 7.767l6.695-5.163.61.792-6.694 5.163a1 1 0 01-1.222 0L.695 3.396z"/></g>'
  }
})
